/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable  */
import React, { lazy, useEffect } from "react";
import Cookies from "js-cookie";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
// import appNav from './appNav';
import Loader from "../components/Loader/Loader";
import CleverLoader from "../components/CleverLoader/CleverLoader"
import ChildRoutes from "./childRoutes";
import NoFound from "../screens/NotFound/NoFound";
// import offlineRoutes from './offlineRoutes';

const Main = lazy(() => import("../screens/Main"));

const PrivateRoute = ({ as: Component, ...props }) => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(true);
  const [fromSalesforce, setFromSalesforce] = React.useState(false);
  const [cleverCode, setCleverCode] = React.useState();
  // console.log(Cookies.get('userId'))
  useEffect(() => {
    const paramStart = window.location.href.indexOf("?");
    const paramSearchString = window.location.href.substr(paramStart);
    const urlParams = new URLSearchParams(paramSearchString);
    const cleverCodeParam = urlParams.get('code');

    if (Cookies.get("userId")) {
      setAuth(true);
    } else if (Boolean(cleverCodeParam)) {
      setCleverCode(cleverCodeParam);
    } else {
      if (window.location.href.indexOf("/loginViaSalesforce?") !== -1) {
        return;
      } else if (
        window.location.href.includes("/license-management/notFound")
      ) {
        return setFromSalesforce(true);
      } else if (window.location.href.indexOf("/school") == "-1") {
        setAuth(false);
        navigate("/");
      } else {
        let codeIndex = window.location.href.indexOf("school/");
        let code = window.location.href.substr(codeIndex + 7);
        navigate(`/${code}`);
      }
    }
    // if (false) {
    //   navigate('../');
    //   setAuth(false);
    // } else {
    //   setAuth(true);
    // }
  }, [navigate]);

  return fromSalesforce ? (
    <NoFound fromSalesforce={fromSalesforce} />
  ) : Boolean(cleverCode) ?
    <CleverLoader cleverCode={cleverCode} />
    : auth ? (
    <Component {...props} />
  ) : (
    <Loader />
  );
};

const appRoutes = [
  {
    path: "/",
    element: <PrivateRoute as={Main} />,
    children: ChildRoutes,
  },
  // {
  //   path: 'offline/',
  //   element: <PrivateRoute as={Main} />,
  //   children: offlineRoutes,
  // }
];

export default appRoutes;
