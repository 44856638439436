/* eslint-disable */

import React, { lazy } from "react";

const RankingDetails = lazy(() =>
  import("../screens/Main/OffLineComponents/RankingDetails")
);
const Dashboard = lazy(() =>
  import("../screens/Main/OffLineComponents/Dashboard")
);
const BadgesDetails = lazy(() =>
  import("../screens/Main/OffLineComponents/BadgesDetails")
);
const NewProject = lazy(() =>
  import("../screens/Main/OffLineComponents/NewProject")
);
const FeedbacksAndRegistraion = lazy(() =>
  import("../screens/Main/FeedbacksAndRegistration/FeedbacksAndRegistration")
);
const Main = lazy(() => import("../screens/Main"));
import { useNavigate } from "react-router-dom";

const PublicRoute = ({ as: Component, ...props }) => {
  // const [cookies] = useCookies();
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(false);

  React.useEffect(() => {
    // if (Cookies.get('userId')) {
    //   // navigate(`app/dashboard/${Cookies.get('School__c__ID')}`);
    //   navigate(`landing-page`);
    // } else {
    //   setAuth(false);
    // }
  }, [navigate]);

  return <Component {...props} />;
};

const routess = [
  {
    name: "dashboard",
    path: ":schoolId",
    element: <Dashboard />,
    // children: SeasonChildRoutes
  },
  // {
  //   name: 'dashboard',
  //   path: 'dashboard/:schoolId',
  //   element: <Dashboard />,
  //   // children: SeasonChildRoutes
  // },
  {
    name: "rankingdetails",
    // path: 'dashboard/:schoolId/rankingdetails/:id',
    // path: ':schoolId/rankingdetails/:id',
    path: ":schoolId/rankingdetails/:id",
    element: <RankingDetails />,
  },
  {
    name: "badgedetails",
    path: ":schoolId/badgedetails/:id",
    element: <BadgesDetails />,
  },
  {
    name: "newProject",
    path: ":schoolId/newProject/:urlprojectId",
    element: <NewProject />,
  },
  {
    name: "feedbacksAndRegistraion",
    path: "/:urlSchoolId/:formText",
    element: <FeedbacksAndRegistraion />,
  },
];

const offlineRoutes = [
  {
    path: "/",
    element: <PublicRoute as={Main} />,
    children: routess,
  },
];

export default offlineRoutes;
